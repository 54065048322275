var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.HEADER_HEIGHT = 0;


function removeClassStartingWith(element, filter) {
    $(element).removeClass(function (index, className) {
        return (className.match(new RegExp("\\S*" + filter + "\\S*", 'g')) || []).join(' ');
    });
    return this;
}

function scrollTo(selector) {
    jQuery(selector).animatescroll( {
        //padding: (jQuery(window).width() > pomlo_app.BREAKPOINTS.SM) ? pomlo_app.HEADER_HEIGHT : pomlo_app.XSMALL_HEADER_HEIGHT,
        scrollSpeed: 600,
        easing: 'easeInOutQuad'
    });
}

jQuery(document).ready(function($) {
    // Init interface
    jQuery(".content-header__item").click(function() {
      jQuery(".content-header__item").removeClass("active");
      jQuery(this).addClass("active");

      jQuery(".content-section__element").addClass("d-none");
      jQuery(jQuery(this).attr("data-toggle")).removeClass("d-none");

      removeClassStartingWith(jQuery("#content-section"), 'color-br--');
      jQuery("#content-section").addClass(jQuery(this).attr("data-color"));
    });

    // Manage Animatescroll links
    jQuery('.scroll-to').removeAttr('href');

    jQuery('.scroll-to').click(function() {
        var scrollLink = jQuery(this).attr('data-link');
        scrollTo(scrollLink);
    });
});

jQuery(window).scroll(function() {

});

jQuery(window).resize(function() {

});
